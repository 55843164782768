<template>
    <v-card dark class="px-8 py-10" outlined tile>
        <h2 class="text-h2">{{ $t('agreement.heading') }}</h2>
        <v-card dark class="pl-4" outlined tile>
            <v-switch v-model="model.intellectual_property" @change="onChange" :disabled="formDisabled" :label="$t('agreement.label_intellectual_property')"/>
            <v-textarea v-model="model.intellectual_property_info" @change="onChange" v-if="model.intellectual_property" :disabled="formDisabled" :label="$t('agreement.label_explanation')" rows="2" autocomplete="chrome-off"/>
        </v-card>
        <v-card dark class="pl-4" outlined tile>
            <v-switch v-model="model.nda" @change="onChange" :disabled="formDisabled" :label="$t('agreement.label_nda')"/>
            <v-textarea v-model="model.nda_info" @change="onChange" v-if="model.nda" :disabled="formDisabled" :label="$t('agreement.label_explanation')" rows="2" autocomplete="chrome-off"/>
        </v-card>
        <div class="overline">{{ $t('agreement.heading_descision_making_process') }}</div>
        <v-card dark class="pl-4" outlined tile>
            <v-switch v-model="model.descision_maker" @change="onChange" :disabled="formDisabled" :label="$t('agreement.label_descision_maker')"/>
            <v-textarea v-model="model.descision_maker_info" @change="onChange" v-if="model.descision_maker" :disabled="formDisabled" :label="$t('agreement.label_descision_maker_info')" rows="2" autocomplete="chrome-off"/>
        </v-card>
        <v-textarea ref="firstInput" v-model="model.external_influence" @change="onChange" :disabled="formDisabled" class="mt-4" :filled="!formDisabled" :label="$t('agreement.label_external_influence')" rows="2" autocomplete="chrome-off"/>
        <v-textarea v-model="model.extra_info" @change="onChange" :disabled="formDisabled" class="mt-2" :filled="!formDisabled" :label="$t('agreement.label_extra_info')" rows="2" autocomplete="chrome-off"/>
        <div class="overline">{{ $t('agreement.heading_arrangements_when') }}</div>
        <v-textarea v-model="model.agree_changes" @change="onChange" :disabled="formDisabled" :filled="!formDisabled" :label="$t('agreement.label_agree_changes')" rows="2" autocomplete="chrome-off"/>
        <v-textarea v-model="model.agree_cancellation" @change="onChange" :disabled="formDisabled" :filled="!formDisabled" :label="$t('agreement.label_agree_cancellation')" rows="2" autocomplete="chrome-off"/>
        <v-textarea v-model="model.agree_supplychange" @change="onChange" :disabled="formDisabled" :filled="!formDisabled" :label="$t('agreement.label_agree_supplychange')" rows="2" autocomplete="chrome-off"/>
        <div class="overline">{{ $t('agreement.heading_investment') }}</div>
        <v-row>
            <v-col cols="6">
                <v-text-field v-model="model.price_design" @change="onChange" :disabled="formDisabled" :label="$t('agreement.label_price_design')" autocomplete="chrome-off"/>
            </v-col>
            <v-col cols="6">
                <v-text-field v-model="model.unit_price" @change="onChange" :disabled="formDisabled" :label="$t('agreement.label_unit_price')" autocomplete="chrome-off"/>
            </v-col>
        </v-row>

        <v-row v-if="fetchLatestNda || fetchLatestAgreement">
            <v-col cols="12" class="py-0">
                <v-btn outlined color="white" class="mt-3 mb-3 mr-3 boxed-icon" @click="downloadNdaPdf" v-if="fetchLatestNda">
                    {{ $t('agreement.download_nda') }}
                    <v-icon right>mdi-cloud-download-outline</v-icon>
                </v-btn>
                <v-btn outlined color="white" class="mt-3 mb-3 mr-3 boxed-icon" @click="downloadAgreementPdf" v-if="fetchLatestAgreement">
                    {{ $t('agreement.download_agreement') }}
                    <v-icon right>mdi-cloud-download-outline</v-icon>
                </v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" class="py-0">
                <v-btn v-if="$inGroupArchitects() && !$isStepApproved(PROJECT_AGREEMENT)" :disabled="!$isStepReleased(PROJECT_AGREEMENT)" class="mt-3 mr-3 boxed-icon" color="primary" tile @click="$approveStep(PROJECT_AGREEMENT)">
                    {{ $t('workflow.agree') }}
                    <v-icon right>mdi-arrow-right</v-icon>
                </v-btn>
                <v-btn v-else-if="$inGroupSales() && !$isStepReleased(PROJECT_AGREEMENT)" class="mt-3 mr-3 boxed-icon" color="primary" tile @click="$releaseStep(PROJECT_AGREEMENT)">
                    {{ $t('workflow.release_to_architect') }}
                    <v-icon right>mdi-arrow-right</v-icon>
                </v-btn>
                <template v-else>
                    <v-btn v-if="$inGroupSales()" class="mt-3 mr-3 boxed-icon" tile color="primary" :disabled="$isStepApproved(PROJECT_AGREEMENT)">
                        {{ $t('global.save') }}
                        <v-icon right>mdi-check</v-icon>
                    </v-btn>
                    <v-btn v-if="$inGroupArchitects()" disabled class="mt-3 mr-3 boxed-icon" color="primary" tile>
                        {{ $t('workflow.agree') }}
                        <v-icon right>mdi-check</v-icon>
                    </v-btn>
                </template>

                <comment-add-dialog v-if="project && $inGroupArchitects() && !$isStepApproved(PROJECT_AGREEMENT)" v-slot="{ attrs, on }" :context="$t('workflow_agreement.context_on_agreement')" :project="project" :user="user" :roles="roles">
                    <v-btn v-bind="attrs" v-on="on" class="mt-3 mr-3 boxed-icon" color="white" tile outlined elevation="0">
                        {{ $t('workflow_agreement.comments_on_agreement') }}
                        <v-icon right>mdi-comment-processing-outline</v-icon>
                    </v-btn>
                </comment-add-dialog>
            </v-col>
        </v-row>
        <v-row v-if="$inGroupSales()">
            <v-col cols="12" class="py-0">
                <v-divider class="mt-5"/>
                <v-btn :outlined="!!fetchLatestAgreement" color="primary" class="mt-3 mb-3 mr-3 boxed-icon" @click="generateAgreementPdf('nl')" v-if="$inGroupSales()" :loading="agreementPdfLoading">
                    {{ $t('agreement.generate_agreement') }} (NL)
                    <v-icon right>mdi-backup-restore</v-icon>
                </v-btn>
                <v-btn :outlined="!!fetchLatestAgreement" color="primary" class="mt-3 mb-3 mr-3 boxed-icon" @click="generateAgreementPdf('en')" v-if="$inGroupSales()" :loading="agreementPdfLoading">
                    {{ $t('agreement.generate_agreement') }} (EN)
                    <v-icon right>mdi-backup-restore</v-icon>
                </v-btn>
                <v-btn :outlined="!!fetchLatestNda" color="primary" class="mt-3 mb-3 mr-3 boxed-icon" @click="generateNdaPdf('')" v-if="$inGroupSales()" :loading="ndaPdfLoading">
                    {{ $t('agreement.generate_nda') }}
                    <v-icon right>mdi-backup-restore</v-icon>
                </v-btn>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
import { debounce } from 'debounce';
import { mapGetters, mapState } from 'vuex';
import { createForm, updateFirestoreForm } from '@/firebase/firestore/projects/forms';
import CommentAddDialog from '@/components/dialogs/CommentAddDialog';
import { AgreementPdfBuilder } from '@/pdf/AgreementPdfBuilder';
import { NdaPDFBuilder } from '@/pdf/NdaPDFBuilder';
import { uploadFiles } from '@/firebase/storage';
import { FORM_AGREEMENT, FORM_START, PROJECT_UPLOAD_AGREEMENT, PROJECT_UPLOAD_NDA } from '@/utils';
import { updateProjectAgreements, updateProjectNda } from '@/firebase/firestore/projects';
import { updateFirestoreWorkflowUpload } from '@/firebase/firestore/workflows';
import { PROJECT_AGREEMENT } from '@/router/projects';

export default {
    name: 'TheProjectAgreement',
    components: {CommentAddDialog},
    data: () => ({
        formAgreement: {},
        formStart: {},
        template: {
            external_influence: '',
            extra_info: '',
            agree_changes: '',
            agree_cancellation: '',
            agree_supplychange: '',
            price_design: '',
            unit_price: '',
            intellectual_property: false,
            intellectual_property_info: '',
            nda: false,
            nda_info: '',
            descision_maker: false,
            descision_maker_info: '',
        },
        model: {},
        agreementPdfLoading: false,
        ndaPdfLoading: false,
    }),

    computed: {
        ...mapState('project', {
            project: 'boundInstance',
        }),

        ...mapState('workflow', {
            workflow: 'boundInstance',
        }),

        ...mapGetters('authentication', {
            user: 'getUser',
            roles: 'getRoles',
        }),

        formDisabled () {
            return !this.$store.getters['workflow/editableOfAgreement'];
        },
        links () {
            const workflow = this.$store.getters['workflow/get'];
            const links = [];

            if (workflow.steps.agreement.agreement) {
                links.push(workflow.steps.agreement.agreement);
            }

            if (workflow.steps.agreement.nda) {
                links.push(workflow.steps.agreement.nda);
            }

            return links;
        },

        fetchLatestAgreement () {
            return this.workflow?.steps?.agreement?.agreement?.url || false;
        },

        fetchLatestNda () {
            return this.workflow?.steps?.agreement?.nda?.url || false;
        },

    },

    watch: {
        formAgreement: function () {
            this.updateModel();
        },

        project: {
            handler () {
                this.updateFirestoreBindings();
            }
        },

        '$store.state.authentication.user': {
            immediate: true,
            handler () {
                this.updateFirestoreBindings();
            }
        },
    },

    async mounted () {
        if (!this.formDisabled) {
            this.$refs.firstInput.focus();
        }
    },

    methods: {
        /**
         * Genereer een overeenkomst-pdf en upload deze
         * @param {String} locale - Taal van de agreement
         */
        generateAgreementPdf (locale) {
            this.agreementPdfLoading = true;

            // Val terug op huidige locale
            if (typeof locale !== 'string' || !locale) {
                locale = this.$i18n.locale;
            }

            const documentName = this.$t('agreement.file_name_agreement', locale, [this.getDocumentDate()]);
            const document = new AgreementPdfBuilder(documentName);
            const model = this.model;

            // Document title
            document.addTitle(this.$t('agreement.heading', locale));

            // Section 1
            document.addHeader(this.$t('agreement.label_intellectual_property', locale));

            let intellectual_property = model.intellectual_property ? this.$t('global.yes', locale) : this.$t('global.no', locale);
            document.addParagraph(`${intellectual_property}.  ${model.intellectual_property_info}`);

            document.addHeader(this.$t('agreement.label_nda', locale));

            let nda = model.nda ? this.$t('global.yes', locale) : this.$t('global.no', locale);
            document.addParagraph(`${nda}.  ${model.nda_info}`);

            // Section 2
            document.addSection(this.$t('agreement.heading_descision_making_process', locale));

            document.addHeader(this.$t('agreement.label_descision_maker', locale));

            let decisionMaker = model.descision_maker ? this.$t('global.yes', locale) : this.$t('global.no', locale);
            document.addParagraph(`${decisionMaker}.  ${model.descision_maker_info}`);

            document.addHeader(this.$t('agreement.label_external_influence', locale));
            document.addParagraph(model.external_influence);

            document.addHeader(this.$t('agreement.label_extra_info', locale));
            document.addParagraph(model.extra_info);

            // Section 3
            document.addSection(this.$t('agreement.heading_arrangements_when', locale));

            document.addHeader(this.$t('agreement.label_agree_changes', locale));
            document.addParagraph(model.agree_changes);

            document.addHeader(this.$t('agreement.label_agree_cancellation', locale));
            document.addParagraph(model.agree_cancellation);

            document.addHeader(this.$t('agreement.label_agree_supplychange', locale));
            document.addParagraph(model.agree_supplychange);

            // Section 4
            document.addSection(this.$t('agreement.heading_investment', locale));

            document.addHeader(this.$t('agreement.label_price_design', locale));
            document.addParagraph(model.price_design);

            document.addHeader(this.$t('agreement.label_unit_price', locale));
            document.addParagraph(model.unit_price);

            const generatedDocument = document.generate();

            generatedDocument.getBlob((blob) => {
                blob.name = documentName + '.pdf';
                this.uploadAgreement(this.project, blob).finally(() => {
                    this.agreementPdfLoading = false;
                });
            });
        },

        /**
         * Genereer een NDA-pdf en upload deze
         * Let op: locale niet in gebruik in NDA
         * @param {String} locale - Taal van de NDA
         */
        async generateNdaPdf (locale) {
            this.ndaPdfLoading = true;

            // Val terug op huidige locale
            // if (typeof locale !== 'string' || !locale) {
            //     locale = this.$i18n.locale;
            // }

            const refForms = this.project.$ref.collection('forms');
            const docStartForm = refForms.doc(FORM_START);

            const snapStartForm = await docStartForm.get();
            const startForm = snapStartForm.data();

            const contact_organisation = startForm?.data?.contact_organisation || '';
            const contact_address = startForm?.data?.contact_address || '';
            const contact_city = startForm?.data?.contact_city || '';
            const contact_name = startForm?.data?.contact_name || '';
            const contact_chamber_of_commerce = startForm?.data?.contact_chamber_of_commerce || '';

            const address = `${contact_city} aan de ${contact_address}`;

            const documentName = this.$t('agreement.file_name_nda', [this.getDocumentDate()]);
            const document = new NdaPDFBuilder(documentName);

            document.addHeader('Partijen:');
            document.addParagraph('Rodruza BV, gevestigd te (6511TJ) Nijmegen aan de St Canisiussingel 20, KvK-nummer 10030798, rechtsgeldig vertegenwoordigd door Ivo Würzner;');
            document.addParagraph('en');
            document.addParagraph(`${contact_organisation}, KvK-nummer ${contact_chamber_of_commerce}, rechtsgeldig vertegenwoordigd door ${contact_name}, wonende te ${address};`);
            document.addParagraph('hierna ook individueel "partij" of gezamenlijk “partijen” genoemd.');

            document.addHeader('Overwegen het volgende:');
            document.addUnorderedList([
                'partijen wensen te onderzoeken of een bepaalde vorm van samenwerking mogelijk is',
                'partijen wensen - voordat zij beslissen - bepaalde vertrouwelijke gegevens van elkaar met betrekking tot de bedrijfsvoering in te zien ',
                'partijen zijn bereid elkaar inzage te verstrekken, onder de voorwaarde dat partijen over en weer de betreffende informatie vertrouwelijk zullen behandelen',
            ]);

            document.addHeader('Zijn overeengekomen:');
            document.addSection('Artikel 1 - Verschaffen van informatie');
            document.addOrderedList([
                'Ondergetekende stemt erin toe dat hij door Rodruza BV wordt voorzien van vertrouwelijke informatie, hierna te noemen: \'informatie\', met betrekking tot: designs voor het Ceramic Design Lab',
                'Het doel van het verstrekken van de vertrouwelijke informatie genoemd in het voorgaande lid is: De informatie wordt gedeeld met het doel om een eventuele maatwerk product levering door Rodruza BV te bespreken.',
                'Partijen leggen schriftelijk vast welke informatie door Rodruza BV aan Ondergetekende in het kader van de uitvoering van deze overeenkomst bekend is gemaakt.',
                'Partijen zullen van tevoren met elkaar overeenkomen op welke wijze de informatie zal worden verstrekt.',
                'Partijen zullen in goed overleg de verstrekking en ontvangst van de informatie coördineren en uitvoeren.',
            ]);

            document.addSection('Artikel 2 - Geheimhouding');
            document.addOrderedList([
                'Ondergetekende verplicht zich hierbij onvoorwaardelijk en onherroepelijk strikte geheimhouding te zullen betrachten omtrent alle informatie die Rodruza BV in het kader van het in artikel 1 lid 2 genoemde doel aan Ondergetekende heeft verstrekt.',
                'Ondergetekende is uitsluitend gerechtigd de informatie te gebruiken voor het doel, waarvoor zij is bestemd.',
                'Ondergetekende verbindt zich de ontvangen informatie op geen enkele wijze commercieel of op een andere manier te zullen gebruiken of te exploiteren zonder de uitdrukkelijke schriftelijke toestemming van Rodruza BV.',
                'Verder zal Ondergetekende deze informatie evenmin geheel of gedeeltelijk aan derden verkopen of op andere wijze ten dienste van derden stellen of ter inzage geven.',
                'Partijen verbinden zich de door hen van elkaar ontvangen informatie uitsluitend te delen met die personeelsleden (van henzelf of van hun dochterondernemingen), die daarvan kennis moeten hebben in het kader van het in lid 2 van dit artikel omschreven doel, maar alleen als deze personeelsleden zich schriftelijk door een speciaal voor dat doel opgestelde verklaring aan de partijen hebben verbonden tot geheimhouding van de informatie. De namen van deze personeelsleden zullen steeds tijdig worden meegedeeld.',
                'Deze verplichtingen gelden zowel voor partijen als voor eventuele door hen in dit kader ingeschakelde of in te schakelen dochterondernemingen, zowel huidige als toekomstige. Partijen garanderen hierbij de stipte nakoming van deze verplichtingen door hun dochterondernemingen. Onder dochterondernemingen van partijen worden verstaan vennootschappen, waarin partijen direct of indirect de meerderheid van het geplaatste (aandelen)kapitaal bezitten of direct of indirect de zeggenschap over de activiteiten van die vennootschappen hebben.'
            ]);

            document.addSection('Artikel 3 - Teruggave stukken');
            document.addOrderedList([
                'Rodruza BV is gerechtigd op elk moment te besluiten geen nadere informatie aan Ondergetekende te verstrekken en/of alle reeds schriftelijk verstrekte informatie van Partij 2 op te eisen.',
                'Ondergetekende zal ieder gebruik van de aan hem verstrekte informatie terstond staken bij afloop van deze overeenkomst en de schriftelijk verstrekte informatie, inclusief de daarvan gemaakte kopieën, aan Rodruza BV retourneren.',
            ]);

            document.addSection('Artikel 4 - Boetebeding');
            document.addOrderedList([
                'Na overtreding van een in de artikelen 1 tot en met 3 omschreven verplichting van Ondergetekende, is Partij 2 direct en zonder sommatie of ingebrekestelling een opeisbare boete van € 10.000 per overtreding verschuldigd, te vermeerderen met 2% van dit bedrag voor iedere dag dat deze overtreding voortduurt. Als na overtreding door Ondergetekende van de verplichtingen uit de artikelen 1 tot en met 3 de geleden schade voor Rodruza BV groter is dan de hoogte van de boete, is Ondergetekende ook verplicht om de aanvullende schade van Rodruza BV te vergoeden.',
                'Overtreding van de geheimhoudingsverplichting van een van de personeelsleden van partijen of van (één van) hun dochterondernemingen geldt ten opzichte van partijen als overtreding van die partij.',
            ]);

            document.addSection('Artikel 5 - Geen geheimhouding');
            document.addParagraph('De in deze overeenkomst opgenomen verplichtingen van Ondergetekende zullen niet gelden voor door Partij 2 ontvangen informatie, waarvan hij kan aantonen:');
            document.addUnorderedList([
                'dat deze aan Ondergetekende reeds eerder bekend was;',
                'dat deze publiekelijk eerder bekend was, of in ieder geval eerder publiekelijk algemeen verkrijgbaar was;',
                'dat deze publiekelijk bekend of verkrijgbaar werd - zonder dat Ondergetekende ter zake aansprakelijk is - sinds de datum waarop Partij 2 de betreffende informatie van Rodruza BV ontving.',
            ]);

            document.addSection('Artikel 6 - Duur geheimhoudingsplicht');
            document.addParagraph('De in artikel 2 bedoelde verplichtingen van Ondergetekende blijven van kracht voor gedurende een periode van 5 jaar, gerekend vanaf de datum van ondertekening van deze overeenkomst');

            document.addSection('Artikel 7- Geschillenbeslechting');
            document.addOrderedList([
                'Op deze overeenkomst is Nederlands recht van toepassing.',
                'De Nederlandse rechter is bevoegd om kennis te nemen van alle geschillen die voortvloeien uit deze overeenkomst. Alle geschillen die voortvloeien uit deze overeenkomst worden exclusief voorgelegd aan de bevoegde rechter van de Rechtbank Gelderland.',
            ]);

            const generatedDocument = document.generate();

            generatedDocument.getBlob((blob) => {
                blob.name = documentName + '.pdf';
                this.uploadNda(this.project, blob).finally(() => {
                    this.ndaPdfLoading = false;
                });
            });
        },

        /**
         * Upload de agreement PDF en koppel deze aan het project
         * @param project
         * @param blob
         * @returns {Promise<void>}
         */
        async uploadAgreement (project, blob) {
            const uploadFolder = `projects/${project.id}/agreement`;
            return uploadFiles(uploadFolder, [blob]).then(async (data) => {
                if (Array.isArray(data) && data.length) {
                    await updateFirestoreWorkflowUpload(this.workflow, PROJECT_AGREEMENT, PROJECT_UPLOAD_AGREEMENT, data[0]);
                    await updateProjectAgreements(project.id, data[0]);
                }
            }).catch((error) => {
                console.log(error);
            });
        },

        async uploadNda (project, blob) {
            const uploadFolder = `projects/${project.id}/nda`;
            return uploadFiles(uploadFolder, [blob]).then(async (data) => {
                if (Array.isArray(data) && data.length) {
                    await updateFirestoreWorkflowUpload(this.workflow, PROJECT_AGREEMENT, PROJECT_UPLOAD_NDA, data[0]);
                    await updateProjectNda(project.id, data[0]);
                }
            }).catch((error) => {
                console.log(error);
            });
        },

        updateModel () {
            this.model = {...this.template, ...this.formAgreement?.data};
        },

        async updateFirestoreBindings () {
            if (!this.$store.getters['authentication/getUser'].uid || !this.project?.id) {
                if (typeof this._firestoreUnbinds['formAgreement'] !== 'undefined') {
                    this.$unbind('formAgreement', true);
                }
                return;
            }

            const refForms = this.project.$ref.collection('forms');
            const docForm = refForms.doc(FORM_AGREEMENT);

            if (!this.$store.getters['workflow/editableOfAgreement']) {
                // Eenwegsbinding (geen synchronisatie vanuit Firestore nodig)
                const snapForm = await docForm.get();
                this.formAgreement = snapForm.data();
                console.debug('Form-data eenmalig ingeladen zonder binding met updates');
            } else {
                // Bind via Vuefire
                return this.$bind('formAgreement', docForm)
                    .finally(() => console.debug('Binding met form-data opgezet'));
            }
        },

        onChange: debounce(function () {
            updateFirestoreForm({
                documentPath: 'projects/' + this.project.id + '/forms/agreement',
                data: createForm({...this.model}, this.formAgreement.users),
            });
        }, 500),

        downloadAgreementPdf () {
            if (this.fetchLatestAgreement) {
                window.open(this.fetchLatestAgreement);
            }
        },

        downloadNdaPdf () {
            if (this.fetchLatestNda) {
                window.open(this.fetchLatestNda);
            }
        },

        getDocumentDate () {
            return new Date().toLocaleString('nl-NL', {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric',
            }).replace(' ', '-');
        }
    },
};
</script>

<style scoped lang="scss">
::v-deep .theme--dark.v-text-field.v-input--is-disabled .v-input__slot {
    &::before {
        border: 0;
    }

    input, textarea, select {
        pointer-events: none;
    }
}
</style>
