import pdfMake from 'pdfmake/build/pdfmake';

// Documentation: http://pdfmake.org/

const host = `${document.location.protocol}//${document.location.host}`;

// Default document definition
const defaultPdfOptions = {
    info: {
        title: '',
        author: 'Rodruza',
    },
    pageOrientation: 'portrait',
    pageSize: 'a4',
    pageMargins: [40, 60, 40, 60],
    styles: {
        title: {
            font: 'TiemposHeadline',
            fontSize: 16,
            margin: [0, 0, 0, 10]
        },
        section: {
            font: 'TiemposText',
            fontSize: 12,
            bold: true,
            margin: [0, 45, 0, 0]
        },
        header: {
            font: 'Oskar',
            fontSize: 11,
            bold: true,
            margin: [0, 20, 0, 0]
        },
        paragraph: {
            fontSize: 11,
            margin: [0, 10, 0, 0]
        }
    },
    defaultStyle: {
        font: 'TiemposText',
        fontSize: 11,
        lineHeight: 1.2,
        // margin: [left, top, right, bottom]
        margin: [0, 0, 0, 5]
    }
};

// Fonts die gebruikt worden in de pdf
const fonts = {
    // download default Roboto font from cdnjs.com
    // Roboto: {
    //     normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
    //     bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
    //     italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
    //     bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
    // },
    TiemposHeadline: {
        normal: `${host}/assets/fonts/TiemposHeadline-Regular.otf`,
        bold: `${host}/assets/fonts/TiemposHeadline-Bold.otf`,
    },
    TiemposText: {
        normal: `${host}/assets/fonts/TiemposText-Regular.otf`,
        bold: `${host}/assets/fonts/TiemposText-Bold.otf`,
        italics: `${host}/assets/fonts/TiemposText-RegularItalic.otf`,
        bolditalics: `${host}/assets/fonts/TiemposText-BoldItalic.otf`,
    },
    Oskar: {
        normal: `${host}/assets/fonts/oskar-oneregular.otf`,
        bold: `${host}/assets/fonts/oskar-oneregular.otf`,
    }

};

export class PdfBuilder {
    constructor (name = '', pdfOptions = {}) {
        this.name = name;
        this.documen = null;

        const defaultOptions = {...defaultPdfOptions, ...{content: [], info: {title: name}}};
        this.docDefinition = {...defaultOptions, ...pdfOptions};
    }

    /**
     * Add content object to the doc definition content
     * @param content
     */
    addContent (content = {}) {

        // voorkomt undefined in tekst
        if ('text' in content) {
            content.text = content.text || '';
        }

        this.docDefinition.content.push(content);
    }

    /**
     * Titel toevoegen aan de document definition content
     * @param text
     */
    addTitle (text) {
        this.addContent({text: text, style: 'title'});
    }

    /**
     * Sectie toevoegen aan de document definition content
     * @param text
     */
    addSection (text) {
        this.addContent({text: text, style: 'section'});
    }

    /**Header Titel toevoegen aan de document definition content
     * @param text
     */
    addHeader (text) {
        this.addContent({text: text, style: 'header'});
    }

    /**
     * Paragraaf toevoegen aan de document definition content
     * @param text
     */
    addParagraph (text) {
        this.addContent({text: text, style: 'paragraph'});
    }

    addUnorderedList (items = []) {
        const objContent = {ul: [...items]};
        this.addContent(objContent);
    }

    addOrderedList (items = []) {
        const objContent = {ol: [...items]};
        this.addContent(objContent);
    }

    /**
     * Generate the document
     * @returns {Document}
     */
    generate () {
        try {
            return this.document = pdfMake.createPdf(this.docDefinition, null, fonts);
        } catch (error) {
            console.error(error);
        }
    }

    download () {
        try {
            this.document.download(this.name);
        } catch (error) {
            console.log(error);
        }
    }

    getDocument () {
        return this.document;
    }
}
