import { PdfBuilder } from '@/pdf/PdfBuilder';

export class AgreementPdfBuilder extends PdfBuilder {
    constructor (name = 'Agreement', pdfOptions = {}) {
        super(name, pdfOptions);

        this.addContent({
            svg: '<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 30.2 10.3" xml:space="preserve" class="logo-white"><g><path d="M4,4.2h0.2c0.3,0,0.6,0.2,0.6,0.6c0,0.4-0.2,0.6-0.6,0.6H4L4,4.2z M5.4,4.8c0-0.7-0.5-1.1-1.2-1.1H3.4v3.2H4 V5.8l0.8,1.1h0.8L4.7,5.8C5.1,5.6,5.4,5.3,5.4,4.8" class="fill-logo"></path><path d="M8,6.3c-0.6,0-1-0.4-1-1.1c0-0.6,0.4-1,1-1s1,0.4,1,1C9,5.9,8.6,6.3,8,6.3 M8,3.6c-1,0-1.7,0.7-1.7,1.7 c0,1,0.7,1.7,1.7,1.7s1.7-0.7,1.7-1.7C9.7,4.3,9,3.6,8,3.6" class="fill-logo"></path><path d="M11.5,4.2h0.2c0.6,0,1,0.4,1,1c0,0.6-0.4,1-1,1h-0.2V4.2z M11.7,3.6h-0.9v3.2h0.9c1,0,1.6-0.6,1.6-1.6 C13.4,4.3,12.7,3.6,11.7,3.6" class="fill-logo"></path><path d="M15.2,4.2h0.2c0.3,0,0.6,0.2,0.6,0.6c0,0.4-0.2,0.6-0.6,0.6h-0.1L15.2,4.2z M16.6,4.8c0-0.7-0.5-1.1-1.2-1.1 h-0.8v3.2h0.6V5.8L16,6.9h0.8l-0.8-1.1C16.3,5.6,16.6,5.3,16.6,4.8" class="fill-logo"></path><path d="M19.6,5.6c0,0.4-0.2,0.7-0.6,0.7c-0.4,0-0.6-0.3-0.6-0.7v-2h-0.6v2c0,0.8,0.5,1.3,1.2,1.3 c0.7,0,1.2-0.5,1.2-1.3v-2h-0.6L19.6,5.6L19.6,5.6z" class="fill-logo"></path><polygon points="21.4,3.6 21.4,4.2 22.7,4.2 21.3,6.9 23.6,6.9 23.6,6.3 22.3,6.3 23.7,3.6 \t\t" class="fill-logo"></polygon><path d="M26.3,5.8h-0.8l0.4-0.9L26.3,5.8z M25.9,3.4l-1.5,3.5h0.6l0.2-0.5h1.3l0.2,0.5h0.7L25.9,3.4z" class="fill-logo"></path></g><path d="M0.6,0.6h29v9.1h-29V0.6z M0,10.3h30.2V0H0V10.3z" class="fill-logo"></path></svg>',
            margin: [0, 0, 0, 20],
            width: 80,
        });
    }

    addHeader (text) {
        if (typeof text === 'string') {
            text.toUpperCase();
        }

        super.addHeader(text);
    }

    addSignature () {
        this.addContent({
            margin: [0, 40, 0, 0],
            columns: [
                {
                    stack: [
                        {text: 'Handtekening Rodruza', margin: [0, 0, 0, 60]},
                        {
                            canvas: [
                                {
                                    type: 'line',
                                    x1: 0,
                                    y1: 5,
                                    x2: 250,
                                    y2: 5,
                                    lineWidth: 0.5
                                }
                            ]
                        },
                    ]
                },
                {
                    stack: [
                        {text: 'Handtekening klant', margin: [0, 0, 0, 60]},
                        {
                            canvas: [
                                {
                                    type: 'line',
                                    x1: 0,
                                    y1: 5,
                                    x2: 250,
                                    y2: 5,
                                    lineWidth: 0.5
                                }
                            ]
                        },
                    ]
                },
            ]
        });
    }

    generate () {
        this.addSignature();
        return super.generate();
    }

}
